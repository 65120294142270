import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Snackbar, DialogTitle, useMediaQuery
} from "@mui/material";
import "./Header.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuIcon from "@mui/icons-material/Menu";
import AddHomeIcon from "@mui/icons-material/AddHome";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { checkLoginStatus } from '../../Services/Checklogin';
const Header = () => {
  // const imageUrl = 'public/assets/images/backgrounds/toShare.png';
  // const encodedImageUrl = getEncodedImageUrl(imageUrl);
  const [width, setWidth] = React.useState(window.innerWidth);
  const isMobile = useMediaQuery("(max-width:700px)");
  const breakpoint = 700;
  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  const [openModal, setOpenModal] = useState(false);
  const [urlToCopy, setUrlToCopy] = useState(
    "https://globalcarbonwarriors.com"
  );
  const encodedUrl = encodeURIComponent(urlToCopy);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const handleButtonClickURL = async () => {
    try {
      await navigator.clipboard.writeText(urlToCopy);
      setSnackbarSeverity("success");
      setSnackbarMessage("URL copied to clipboard!");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to copy to clipboard.");
    } finally {
      setSnackbarOpen(true);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleInviteToJoin = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const header_styles = {
    container_styles: {
      background: "#00e700",
      maxWidth: "100%",
    },
    login_btn: {
      marginRight: "10px",
      background: "yellow",
      textTransform: "none",
      color: "black ",
      padding: "15px",
      borderRadius: "5px",
      fontSize: "14px",
      height: "25px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      fontFamily: '"Poppins",sans-serif',
      "&:hover": {
        backgroundColor: "yellow",
        color: "black !important",
      },
    },
    savetohomepage_btn: {
      background: "white",
      textTransform: "none",
      color: "black",
      borderRadius: "5px",
      marginRight: "10px",
      fontSize: "14px",
      padding: "15px",
      height: "25px",
      width: "26%",
      fontFamily: "Poppins",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      "&:hover": {
        backgroundColor: "yellow",
      },
    },
    nav_link_font: {
      color: "black",
      fontFamily: "Poppins",
      cursor: "pointer",
    },
    invite_to_join: {
      background: "yellow",
      textTransform: "none",
      color: "black",
      borderRadius: "5px",
      marginRight: "2px",
      marginTop: "0.5rem",
      fontSize: "10px",
      padding: "15px",
      height: "25px",
      fontFamily: "Poppins",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      "&:hover": {
        backgroundColor: "yellow",
      },
    },
    invite_to_join1: {
      background: "white",
      textTransform: "none",
      color: "black",
      borderRadius: "5px",
      marginRight: "10px",
      marginTop: "0.5rem",
      fontSize: "10px",
      padding: "15px",
      height: "20px",
      fontFamily: "Poppins",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      "&:hover": {
        backgroundColor: "yellow",
      },
    },
  };
  const [showAddToHomeScreenButton, setShowAddToHomeScreenButton] = useState(false);
  const deferredPrompt = useRef(null);

  useEffect(() => {
    console.log("Component mounted");

    const handleBeforeInstallPrompt = (e) => {
      console.log("beforeinstallprompt event fired");
      e.preventDefault();
      deferredPrompt.current = e;
      setShowAddToHomeScreenButton(true);
    };

    if ("BeforeInstallPromptEvent" in window) {
      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    }

    return () => {
      if ("BeforeInstallPromptEvent" in window) {
        window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      }
    };
  }, []);

  const handleAddToHomeScreen = () => {
    if (deferredPrompt.current) {
      deferredPrompt.current.prompt();
      deferredPrompt.current.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt.current = null;
        setShowAddToHomeScreenButton(false);
      });
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [menuAnchor, setMenuAnchor] = useState(null);
  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchor(null);
  };
  const isLoggedIn = checkLoginStatus(); 
  const handleMenuClick = (url) => {
    if (url === '/logout') {
      localStorage.removeItem('user'); // Clear user data
      // Optionally, redirect to the login page or homepage
      window.location.href = '/login'; // Redirect to login page
    } else {
      window.open(url, "_blank");
    }
    closeMenu();
  };
  const handleButtonClick = () => {
    if (checkLoginStatus()) {
      window.open('/investor-ready-test');
    } else {
      window.open("/login",'_blank');
    }
  };
  const handleApplyforfund = () => {
    if (checkLoginStatus()) {
      window.open('/profile/cleantech/apply-for-fund');
    } else {
      window.open("/login",'_blank');
    }
  };
  return (
    <Box className="head">
      <Grid
        container
        xs={12}
        sx={header_styles.container_styles}
        direction="row"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item xs={3}>
          <Box className="imgBoxGCW">
            <img
              className="responsiveImage"
              src={process.env.PUBLIC_URL + "/assets/images/logos/GCWLogo.webp"}
              alt="green"
              onClick={() => window.open("/")}
            />
          </Box>
        </Grid>
        <Grid item xs={9} display="flex" justifyContent="flex-end">
          <Box display="flex">
            {!isMobile && (
              <>
                <Grid item xs={8}>
                  <Grid
                    container
                    direction="row"
                    xs={12}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        id="links"
                        onClick={handleButtonClick}
                        sx={{
                          ...header_styles.nav_link_font,
                          padding: "0 8px",
                          fontSize: width <= breakpoint ? "15" : "18px",
                          color: "white",
                          opacity: 1,
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          lineHeight: "30px",
                          letterSpacing: "0em",
                          textAlign: "left",
                        }}
                      >
                        <Box title="Investor Ready Test">
                          <EditNoteIcon fontSize="large" />
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        id="links"
                        onClick={handleApplyforfund}
                        sx={{
                          ...header_styles.nav_link_font,
                          padding: "0 8px",
                          fontSize: width <= breakpoint ? "15" : "18px",
                          color: "white",
                          opacity: 1,
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          lineHeight: "30px",
                          letterSpacing: "0em",
                          textAlign: "left",
                        }}
                      >
                        <Box title="Apply for Fundraise">
                          <MonetizationOnIcon fontSize="large" />
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        id="links"
                        onClick={handleAddToHomeScreen}
                        sx={{
                          ...header_styles.nav_link_font,
                          padding: "0 8px",
                          fontSize: width <= breakpoint ? "15" : "18px",
                          color: "white",
                          opacity: 1,
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          lineHeight: "30px",
                          letterSpacing: "0em",
                          textAlign: "left",
                        }}
                      >
                        <Box title="Save to Home Screen">
                          <AddHomeIcon fontSize="large"></AddHomeIcon>
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    id="add-to-home-screen-button"
                    onClick={handleInviteToJoin}
                    sx={{
                      ...header_styles.nav_link_font,
                      padding: "0 8px",
                      fontSize: width <= breakpoint ? "15" : "18px",
                      color: "white",
                      opacity: 1,
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      lineHeight: "30px",
                      letterSpacing: "0em",
                      textAlign: "left",
                    }}
                  >
                    <Box title="Invite to Join">
                      <GroupAddIcon fontSize="large"></GroupAddIcon>
                    </Box>
                  </Typography>
                </Grid>
                <IconButton onClick={openMenu} aria-label="Open Menu" sx={{padding: "0px 8px 12px 8px"}}>
                  <MenuIcon
                    style={{
                      width: "30px",
                      height: "30px",
                      marginBottom: "-3px",
                    }}
                    className="menu-icon-desktop"
                  />
                </IconButton>

                <Menu
                  id="app-menu"
                  anchorEl={menuAnchor}
                  open={Boolean(menuAnchor)}
                  onClose={closeMenu}
                >
              {isLoggedIn ? (
               <MenuItem onClick={() => handleMenuClick('/logout')} aria-label="Logout">
                Logout
               </MenuItem>
        ) : (
                <MenuItem onClick={() => handleMenuClick('/login')} aria-label="Login">
                 Login
                </MenuItem>
                  )}
                  <MenuItem onClick={() => handleMenuClick("/")} aria-label="Home">
                    Home
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClick("/blog")} aria-label="Blog">
                    Blog
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuClick("/about")} aria-label="About Us">
                    About Us
                  </MenuItem>
                </Menu>

              </>
            )}
            {isMobile && (
              <>
                <Box className="mob-header-icon">
                  <Button aria-label="InvestorReadyTest" style={{ minWidth: '0px' }} onClick={handleButtonClick}>
                    <Box color="white" title="Investor Ready Test">
                      <EditNoteIcon style={{ justifyContent: "space-between" }} fontSize="medium" />
                    </Box>
                  </Button>
                  <Button aria-label="ApplyForFundraiser" style={{ minWidth: '0px' }} onClick={handleApplyforfund}>
                    <Box color="white" title="Apply for Fundraise">
                      <MonetizationOnIcon fontSize="medium" />
                    </Box>
                  </Button>
                  <Button aria-label="SaveToHomeScreen" style={{ minWidth: '0px' }} id="add-to-home-screen-button" onClick={handleAddToHomeScreen}>
                    <Box color="white" title="Save to Home Screen">
                      <AddHomeIcon fontSize="medium" />
                    </Box>
                  </Button>
                  <Button aria-label="InviteToJoin" style={{ minWidth: '0px' }} onClick={handleInviteToJoin}>
                    <Box color="white" title="Invite to Join">
                      <GroupAddIcon fontSize="medium" />
                    </Box>
                  </Button>
                  <IconButton onClick={handleMenuOpen}>
                    <MenuIcon
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                      className="menuitem1"
                    />
                  </IconButton>
                  <Menu
                    id="mobile-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={isLoggedIn ? () => handleMenuClick('/logout') : () => handleMenuClick('/login')}>
                    {isLoggedIn ? 'Logout' : 'Login'}
                    </MenuItem>                    
                    <MenuItem onClick={() => window.open("/")}>Home</MenuItem>
                    <MenuItem onClick={() => window.open("/blog")}>Blog</MenuItem>
                    <MenuItem onClick={() => window.open("/about")}>About Us</MenuItem>
                  </Menu>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog open={openModal}>
        <DialogTitle
          variant="h6"
          style={{ color: "green" }}
          className="custom-dialog-title"
          sx={{ position: "relative" }}
        >
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="h6">Invite To Join</Typography>
            </Grid>
            <Grid item sx={{ position: "absolute", right: 0 }}>
              <HighlightOffIcon
                className="close-bt"
                onClick={handleCloseModal}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Box
                style={{
                  width: "100%",
                  height: "4rem",
                  border: "1px solid gray",
                  marginBottom: "1.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1">{urlToCopy}</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                aria-label="Copy"
                variant="contained"
                onClick={handleButtonClickURL}
                style={{ backgroundColor: "yellow", color: "black" }}
              >
                Copy
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                className="text-wrapper"
                style={{ color: "green" }}
              >
                Share Through Channels
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <a
                  href={`mailto:?subject=Join%20Us&body=Check%20this%20out:%20${encodeURIComponent(urlToCopy)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/images/icons/gmail.webp" alt="Gmail" className="logo-css1" />
                </a>
                <a
                  href={`https://telegram.me/share/${encodeURIComponent(urlToCopy)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/icons/telegram.webp"
                    alt="Telegram"
                    className="logo-css1"
                  />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(urlToCopy)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/images/icons/linkdin.webp" alt="LinkedIn" className="logo-css1" />
                </a>
                <a
                  href={`https://api.whatsapp.com/send?text=Check%20this%20out:%20${encodeURIComponent(urlToCopy)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/images/icons/wl.webp" alt="WhatsApp" className="logo-css1" />
                </a>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/icons/InstaLogo.webp"
                    alt="Instagram"
                    className="logo-css1"
                  />
                </a>
                <a
                  href="https://www.messenger.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/icons/Message.webp"
                    alt="Messenger"
                    className="logo-css1"
                  />
                </a>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Box
              className={`snackbar ${snackbarSeverity}`}
              style={{ bottom: "60px" }}
            >
              {snackbarMessage}
            </Box>
          </Snackbar>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default Header;